import * as React from 'react'
import Layout from '../../components/layout'
import PageTransition from 'gatsby-plugin-page-transitions';

const ContactPage = ({ location }) => {
  return (
    <Layout pageTitle="Contact Us Success" pageClass="template-contact-success" location={location}>
      <PageTransition>
        <div className="container">
          <div className="contact-page grid">
            <div className="contact-page__title">
              <h1 className="h2">Thank you!</h1>
              <p className="contact-page__text">Your message has been sent. We'll make sure we get back to you within the next 48 hours.</p>
            </div>
          </div>
        </div>
      </PageTransition>
    </Layout>
  )
}

export default ContactPage